import { useEffect } from "react";

function App() {
  useEffect(() => {
    const onLoad = async () => {
      const roomName = window.location.pathname.replace("/", "");
      if (roomName) {
        try {
          const meeting = new window.Metered.Meeting();
          await meeting.join({
            roomURL: `1gate.metered.live/${roomName}`,
          });
          await meeting.startScreenShare();
        } catch (error) {
          window.close();
        }
      }
    };

    onLoad();
  }, []);

  return <div></div>;
}

export default App;
